export const VIDEO_API_REQUEST = 'VIDEO_API_REQUEST';
export const VIDEO_API_SUCCESS = 'VIDEO_API_SUCCESS';
export const VIDEO_API_ERROR = 'VIDEO_API_ERROR';
export const ADD_VIDEO_MODAL_SHOW = 'ADD_VIDEO_MODAL_SHOW';
export const ADD_VIDEO_MODAL_CLOSE = 'ADD_VIDEO_MODAL_CLOSE';
export const SET_VIDEO_TO_ADD = 'SET_VIDEO_TO_ADD';
export const ADD_VIDEO_REQUEST = 'ADD_VIDEO_REQUEST';
export const ADD_VIDEO_SUCCESS = 'ADD_VIDEO_SUCCESS';
export const ADD_VIDEO_ERROR = 'ADD_VIDEO_ERROR';
export const REMOVE_VIDEO_REQUEST = 'REMOVE_VIDEO_REQUEST';
export const REMOVE_VIDEO_SUCCESS = 'REMOVE_VIDEO_SUCCESS';
export const REMOVE_VIDEO_ERROR = 'REMOVE_VIDEO_ERROR';
export const EDIT_VIDEO_MODAL_SHOW = 'EDIT_VIDEO_MODAL_SHOW';
export const EDIT_VIDEO_MODAL_CLOSE = 'EDIT_VIDEO_MODAL_CLOSE';
export const SET_VIDEO_TO_EDIT = 'SET_VIDEO_TO_EDIT';
export const SET_VIDEO_TAG_TO_EDIT = 'SET_VIDEO_TAG_TO_EDIT';
export const UPDATE_VIDEO_REQUEST = 'UPDATE_VIDEO_REQUEST';
export const UPDATE_VIDEO_SUCCESS = 'UPDATE_VIDEO_SUCCESS';
export const UPDATE_VIDEO_ERROR = 'UPDATE_VIDEO_ERROR';

export const PICTURE_API_REQUEST = 'PICTURE_API_REQUEST';
export const PICTURE_API_SUCCESS = 'PICTURE_API_SUCCESS';
export const PICTURE_API_ERROR = 'PICTURE_API_ERROR';
export const ADD_PICTURE_MODAL_SHOW = 'ADD_PICTURE_MODAL_SHOW';
export const ADD_PICTURE_MODAL_CLOSE = 'ADD_PICTURE_MODAL_CLOSE';
export const SET_PICTURE_TO_ADD = 'SET_PICTURE_TO_ADD';
export const ADD_PICTURE_REQUEST = 'ADD_PICTURE_REQUEST';
export const ADD_PICTURE_SUCCESS = 'ADD_PICTURE_SUCCESS';
export const ADD_PICTURE_ERROR = 'ADD_PICTURE_ERROR';
export const REMOVE_PICTURE_REQUEST = 'REMOVE_PICTURE_REQUEST';
export const REMOVE_PICTURE_SUCCESS = 'REMOVE_PICTURE_SUCCESS';
export const REMOVE_PICTURE_ERROR = 'REMOVE_PICTURE_ERROR';
export const EDIT_PICTURE_MODAL_SHOW = 'EDIT_PICTURE_MODAL_SHOW';
export const EDIT_PICTURE_MODAL_CLOSE = 'EDIT_PICTURE_MODAL_CLOSE';
export const SET_PICTURE_TO_EDIT = 'SET_PICTURE_TO_EDIT';
export const SET_PICTURE_TAG_TO_EDIT = 'SET_PICTURE_TAG_TO_EDIT';
export const UPDATE_PICTURE_REQUEST = 'UPDATE_PICTURE_REQUEST';
export const UPDATE_PICTURE_SUCCESS = 'UPDATE_PICTURE_SUCCESS';
export const UPDATE_PICTURE_ERROR = 'UPDATE_PICTURE_ERROR';

export const TAG_API_REQUEST = 'TAG_API_REQUEST';
export const TAG_API_SUCCESS = 'TAG_API_SUCCESS';
export const TAG_API_ERROR = 'TAG_API_ERROR';
export const SET_ACTUAL_TAG = 'SET_ACTUAL_TAG';
export const SET_NEW_TAG = 'SET_NEW_TAG';
export const ADD_TAG_REQUEST = 'ADD_TAG_REQUEST';
export const ADD_TAG_SUCCESS = 'ADD_TAG_SUCCESS';
export const ADD_TAG_ERROR = 'ADD_TAG_ERROR';
